import Entity from '../Entity'
import Technician from './Technician'
import Moment from 'moment'

export default class Planning extends Entity {
  constructor(data) {
    super(data.id)
    if (data.meta) {
      this.filial_id = data.meta.filial_id
      this.filial_name = data.meta.filial_name
      this.filial_img = data.meta.filial_img
      this.depot_id = data.meta.depot_id
      this.depot_name = data.meta.depot_name
      this.depot_zipCode = data.meta.depot_zipCode
      this.depot_nbr = data.meta.depot_nbr
      this.week_nbr = data.meta.week_nbr
      this.week_id = data.meta.week_id
      this.year = data.meta.year
      this.first_day_of_week = data.meta.first_day_of_week
      this.last_day_of_week = data.meta.last_day_of_week
      this.day = data.meta.day
      this.month_id = data.meta.month_id
      this.month_nbr = data.meta.month_nbr
      // set days
      // planning AMO
      this.dayPlanning = this.getFormatDay(this.day)
      // planning vt et poseurs
      this.monday = 'Lundi '
      this.tuesday = 'Mardi '
      this.wednesday = 'Mercredi '
      this.thursday = 'Jeudi '
      this.friday = 'Vendredi '
      // this.monday = 'Lundi ' + this.getRelativeDay(this.first_day_of_week, 0)
      // this.tuesday = 'Mardi ' + this.getRelativeDay(this.first_day_of_week, 1)
      // this.wednesday =
      //   'Mercredi ' + this.getRelativeDay(this.first_day_of_week, 2)
      // this.thursday = 'Jeudi ' + this.getRelativeDay(this.first_day_of_week, 3)
      // this.friday = 'Vendredi ' + this.getRelativeDay(this.first_day_of_week, 4)
      this.saturday = 'Samedi ' + this.getRelativeDay(this.first_day_of_week, 5)
      this.sunday = 'Dimanche ' + this.getRelativeDay(this.first_day_of_week, 6)
    }

    this.g_planifie = 0
    this.g_planifie_count = 0
    this.g_pose = 0
    this.g_pose_count = 0
    this.g_planifie_pose = 0
    this.g_ca = 0
    // set technicians
    if (data.technicians && Array.isArray(data.technicians)) {
      this.technicians = data.technicians.map(dataTech => {
        return Technician.create(dataTech)
      })
    } else {
      this.technicians = []
    }
    // set commercials
    if (data.commercials && Array.isArray(data.commercials)) {
      this.commercials = data.commercials.map(dataTech => {
        return Technician.create(dataTech)
      })
    } else {
      this.commercials = []
    }
  }

  getRelativeDay(day, inc) {
    let date = ''
    if (day) {
      date = Moment(day, 'YYYY-MM-DD')
        .add(inc, 'days')
        .format('DD MMMM YYYY')
    }
    return date
  }
  getFormatDay(date) {
    let day = ''
    if (date) {
      let dateFormat = Moment(date, 'YYYY-MM-DD')
        .format('YYYY-MM-DD')
        .split('-')
        .join('-')
      const event = new Date(dateFormat)
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      day = event.toLocaleDateString(undefined, options)
      return day
    }
    return day
  }
  // filiale commercials
  computeGlobalValuesCPlanning() {
    this.dayStat = {
      loading: true,
      days: []
    }
    for (let index = 0; index < 7; index++) {
      this.dayStat.days[index] = {
        fd_s_planifie: 0,
        fd_d_planifie: 0,
        fd_r_planifie: 0,
        fd_m_planifie: 0,
        fd_pl_planifie: 0,
        fd_pi_cplanifie: 0,
        fd_vs_planifie: 0,
        fd_ite_planifie: 0,
        fd_iti_planifie: 0,
        fd_planifie: 0,

        fd_s_pose: 0,
        fd_d_pose: 0,
        fd_r_pose: 0,
        fd_m_pose: 0,
        fd_pl_pose: 0,
        fd_pi_pose: 0,
        fd_vs_pose: 0,
        fd_ite_pose: 0,
        fd_iti_pose: 0,
        fd_pose: 0,

        fd_pose_planifie: 0,
        fd_h_trajet: 0,
        fd_h_pose: 0,
        fd_h: 0,
        fd_ca: 0
      }
    }
    this.commercials.forEach(t => {
      for (let index = 0; index < t.days.length; index++) {
        const d = t.days[index]
        this.dayStat.days[index].fd_s_planifie += parseInt(d.total_s_planifie)
        this.dayStat.days[index].fd_d_planifie += parseInt(d.total_d_planifie)
        this.dayStat.days[index].fd_r_planifie += parseInt(d.total_r_planifie)
        this.dayStat.days[index].fd_m_planifie += parseInt(d.total_m_planifie)
        this.dayStat.days[index].fd_pl_planifie += parseInt(d.total_p_planifie)
        this.dayStat.days[index].fd_pi_cplanifie += parseInt(
          d.total_pignon_planif
        )
        this.dayStat.days[index].fd_vs_planifie += parseInt(d.total_vs_planifie)
        this.dayStat.days[index].fd_ite_planifie += parseInt(d.ite_day)
        this.dayStat.days[index].fd_iti_planifie += parseInt(d.iti_day)
        this.dayStat.days[index].fd_planifie += parseInt(
          d.total_total_m_planifie
        )
        this.dayStat.days[index].fd_ite_pose += parseInt(d.ite_day)
        this.dayStat.days[index].fd_iti_pose += parseInt(d.iti_day)
        this.dayStat.days[index].fd_h_trajet += parseInt(d.total_trajet)
        this.dayStat.days[index].fd_h_pose += parseInt(d.total_pose)
        this.dayStat.days[index].fd_h += parseInt(d.total_total_h)
        this.dayStat.days[index].fd_ca += parseInt(d.ca_d)
      }
    })
    this.dayStat.days.forEach(d => {
      if (d.fd_planifie) {
        d.fd_pose_planifie = (d.fd_pose / d.fd_planifie) * 100
      }
    })
    this.dayStat.loading = false
  }
  computeGlobalValues() {
    this.dayStat = {
      loading: true,
      days: []
    }
    for (let index = 0; index < 7; index++) {
      this.dayStat.days[index] = {
        fd_s_planifie: 0,
        fd_d_planifie: 0,
        fd_r_planifie: 0,
        fd_m_planifie: 0,
        fd_pl_planifie: 0,
        fd_pi_planifie: 0,
        fd_vs_planifie: 0,
        fd_ite_planifie: 0,
        fd_iti_planifie: 0,
        fd_planifie: 0,

        fd_s_pose: 0,
        fd_d_pose: 0,
        fd_r_pose: 0,
        fd_m_pose: 0,
        fd_pl_pose: 0,
        fd_pi_pose: 0,
        fd_vs_pose: 0,
        fd_iti_pose: 0,
        fd_ite_pose: 0,
        fd_pose: 0,

        fd_pose_planifie: 0,
        fd_h_trajet: 0,
        fd_h_pose: 0,
        fd_h: 0,
        fd_ca: 0
      }
    }
    this.g_planifie = 0
    this.g_planifie_count = 0
    this.g_pose = 0
    this.g_pose_count = 0
    this.g_planifie_pose = 0
    this.g_ca = 0
    this.technicians.forEach(t => {
      this.g_planifie += parseInt(t.total_planifie_w)
      this.g_ca += parseInt(t.ca_w)
      for (let index = 0; index < t.days.length; index++) {
        const d = t.days[index]
        d.leads.forEach(lead => {
          if (lead.sav === 0) {
            this.g_planifie_count++
            // if (lead.csubstage_id === 1584) {
            //   this.g_pose_count++
            //   this.g_pose += parseInt(lead.total_pose)
            // }
          }
        })
        this.dayStat.days[index].fd_s_planifie += parseInt(d.total_s_planifie)
        this.dayStat.days[index].fd_d_planifie += parseInt(d.total_d_planifie)
        this.dayStat.days[index].fd_r_planifie += parseInt(d.total_r_planifie)
        this.dayStat.days[index].fd_m_planifie += parseInt(d.total_m_planifie)
        this.dayStat.days[index].fd_pl_planifie += parseInt(d.total_p_planifie)
        this.dayStat.days[index].fd_pi_planifie += parseInt(
          d.total_pignon_planifie
        )
        this.dayStat.days[index].fd_vs_planifie += parseInt(d.total_vs_planifie)
        this.dayStat.days[index].fd_iti_planifie += parseInt(d.iti_day)
        this.dayStat.days[index].fd_planifie += parseInt(
          d.total_total_m_planifie
        )
        this.dayStat.days[index].fd_s_pose += parseInt(d.total_s_pose)
        this.dayStat.days[index].fd_d_pose += parseInt(d.total_d_pose)
        this.dayStat.days[index].fd_r_pose += parseInt(d.total_r_pose)
        this.dayStat.days[index].fd_m_pose += parseInt(d.total_m_pose)
        this.dayStat.days[index].fd_pl_pose += parseInt(d.total_p_pose)
        this.dayStat.days[index].fd_pi_pose += parseInt(d.total_pignon_pose)
        this.dayStat.days[index].fd_vs_pose += parseInt(d.total_vs_pose)
        this.dayStat.days[index].fd_iti_pose += parseInt(d.total_iti_pose)
        this.dayStat.days[index].fd_pose += parseInt(d.total_total_m_pose)
        this.dayStat.days[index].fd_h_trajet += parseInt(d.total_trajet)
        this.dayStat.days[index].fd_h_pose += parseInt(d.total_pose)
        this.dayStat.days[index].fd_h += parseInt(d.total_total_h)
        this.dayStat.days[index].fd_ca += parseInt(d.ca_d)
      }
    })
    if (this.g_planifie) {
      this.g_planifie_pose = Math.round((this.g_pose / this.g_planifie) * 100)
    }
    this.dayStat.days.forEach(d => {
      if (d.fd_planifie) {
        d.fd_pose_planifie = (d.fd_pose / d.fd_planifie) * 100
      }
    })
    this.dayStat.loading = false
  }

  static create(data) {
    if (!data) {
      throw new Error('Planning::create: "data" is undefined')
    }
    return new Planning(data)
  }
}
