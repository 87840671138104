import Entity from '../Entity'

export default class Deal extends Entity {
  constructor(data) {
    super(data.id)
    // planningTech
    this.created_at = data.created_at
    this.is_copy = data.is_copy
    this.surface_iti = data.surface_iti ? data.surface_iti : 0
    this.surface_iti_pose = data.surface_iti_pose ? data.surface_iti_pose : 0
    this.confirmation = data.confirmation
    this.dossier = data.dossier
    this.ite_pose = data.ite_pose
    this.camion = data.camion
    this.sav = data.sav
    this.photo = data.photo
    this.prj_ville = data.prj_ville
    this.prj_adresse = data.prj_adresse
    this.type_heating = data.type_heating
    this.type_travaux = data.type_travaux
    this.travaux = data.travaux
    this.type_isolation = data.type_isolation
    this.precarite = data.precarite
    this.cpipeline_id = data.cpipeline_id
    this.csubstage_id = data.csubstage_id
    this.cstage_id = data.cstage_id
    this.total = data.total ? data.total : 0
    this.total_pose = data.total_pose ? data.total_pose : 0
    this.surface_vide_sanitaire_pose = data.surface_vide_sanitaire_pose
      ? data.surface_vide_sanitaire_pose
      : 0
    this.surface_vide_sanitaire = data.surface_vide_sanitaire
      ? data.surface_vide_sanitaire
      : 0
    this.surface_plafond_pose = data.surface_plafond_pose
      ? data.surface_plafond_pose
      : 0
    this.surface_plafond = data.surface_plafond ? data.surface_plafond : 0
    this.surface_pignon_pose = data.surface_pignon_pose
      ? data.surface_pignon_pose
      : 0
    this.surface_pignon = data.surface_pignon ? data.surface_pignon : 0
    this.surface_mur_pose = data.surface_mur_pose ? data.surface_mur_pose : 0
    this.surface_mur = data.surface_mur ? data.surface_mur : 0
    this.surface_rampant_pose = data.surface_rampant_pose
      ? data.surface_rampant_pose
      : 0
    this.surface_rampant = data.surface_rampant ? data.surface_rampant : 0
    this.surface_deroule_pose = data.surface_deroule_pose
      ? data.surface_deroule_pose
      : 0
    this.surface_deroule = data.surface_deroule ? data.surface_deroule : 0
    this.surface_souffle_pose = data.surface_souffle_pose
      ? data.surface_souffle_pose
      : 0
    this.surface_souffle = data.surface_souffle ? data.surface_souffle : 0
    this.trajet_pose_tech_h_sum = data.trajet_pose_tech_h_sum
      ? data.trajet_pose_tech_h_sum
      : 0
    this.trajet_time_tech = data.trajet_time_tech ? data.trajet_time_tech : 0
    this.pose_time_tech = data.pose_time_tech ? data.pose_time_tech : 0
    this.zipCode = data.zipCode
    this.agent_last_name = data.agent_last_name ? data.agent_last_name : ''
    this.agent_first_name = data.agent_first_name ? data.agent_first_name : ''
    // agent (intervenant)
    this.title_agent = `${
      this.agent_first_name ? this.agent_first_name + ' ' : ''
    } ${this.agent_last_name ? this.agent_last_name : ''}`

    this.contact_last_name = data.contact_last_name
      ? data.contact_last_name
      : ''
    this.contact_first_name = data.contact_first_name
      ? data.contact_first_name
      : ''
    // contact
    this.title_contact = ` ${
      this.contact_last_name ? this.contact_last_name : ''
    } ${this.contact_first_name ? this.contact_first_name + ' ' : ''}`

    this.hour_trav_planif = data.hour_trav_planif
      ? data.hour_trav_planif.split(':')[0] + 'h'
      : data.hour_trav_planif
    this.date_travaux = data.date_travaux
    this.rdv_le = data.rdv_le
    this.fin_rdv = data.fin_rdv
    this.duplicate = data.duplicate
    this.hour = data.hour
    this.date_fin_previsite = data.date_fin_previsite
    this.date_fin_visite_tech = data.date_fin_visite_tech
    this.date_previsite = data.date_previsite
    this.date_visite_tech = data.date_visite_tech
    this.contact_id = data.contact_id
    this.project_id = data.project_id
    this.comm_ite_first_name = data.comm_ite_first_name
      ? data.comm_ite_first_name
      : ''
    this.comm_ite_last_name = data.comm_ite_last_name
      ? data.comm_ite_last_name
      : ''
    // previsiteur
    this.title_comm_ite = `${
      this.comm_ite_first_name ? this.comm_ite_first_name + ' ' : ''
    } ${this.comm_ite_last_name ? this.comm_ite_last_name : ''}`

    this.resp_plan_first_name = data.resp_plan_first_name
      ? data.resp_plan_first_name
      : ''
    this.resp_plan_last_name = data.resp_plan_last_name
      ? data.resp_plan_last_name
      : ''
    // resp.planning
    this.title_resp_plan = `${
      this.resp_plan_first_name ? this.resp_plan_first_name + ' ' : ''
    } ${this.resp_plan_last_name ? this.resp_plan_last_name : ''}`

    this.comm_terrain_first_name = data.comm_terrain_first_name
      ? data.comm_terrain_first_name
      : ''
    this.comm_terrain_last_name = data.comm_terrain_last_name
      ? data.comm_terrain_last_name
      : ''
    // commercial terrain
    this.title_comm_terrain = `${
      this.comm_terrain_first_name ? this.comm_terrain_first_name + ' ' : ''
    } ${this.comm_terrain_last_name ? this.comm_terrain_last_name : ''}`
    // planningCommIte
    this.confirmation_previsite = data.confirmation_previsite

    this.disabled = data.disabled
    this.color = data.color
    this.deal_pipe_id = data.deal_pipe_id
    this.comments = {
      count: data.nbr_plann_comments,
      loaded: false,
      list: []
    }
    // commercials ITE
    this.trajet_time_ite = data.trajet_time_ite ? data.trajet_time_ite : 0
    this.pose_time_ite = data.pose_time_ite ? data.pose_time_ite : 0
    this.trajet_pose_ite_h_sum = data.trajet_pose_ite_h_sum
      ? data.trajet_pose_ite_h_sum
      : 0
    this.surface_ite = data.surface_ite ? data.surface_ite : 0
    this.total_m_ite = data.total_m_ite ? data.total_m_ite : 0
    this.hour_previsite = data.hour_previsite
      ? data.hour_previsite.split(':')[0] + 'h'
      : data.hour_previsite
    this.hour_visite_tech = data.hour_visite_tech
      ? data.hour_visite_tech.split(':')[0] + 'h'
      : data.hour_visite_tech
  }

  setComments(comments) {
    this.comments.list = comments
    this.comments.loaded = true
  }

  static create(data) {
    if (!data) {
      throw new Error('Deal::create: "data" is undefined')
    }
    return new Deal(data)
  }
}
