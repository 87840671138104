import * as moment from 'moment'
import Axios from '@/axios'
import Pipeline from '../../models/planning/Pipeline'
import Planning from '../../models/planning/Planning'
import DayRow from '../../models/planning/DayRow'
import domain from '@/environment'
import Router from '@/router'

let inti_Planning_Request = null
let fetch_Planning_Request = null
const state = {
  technicians: [],
  planning: null,
  filiales: [],
  deposits: [],
  years: [],
  weeks: [],
  error: null,
  processing: false,
  planningPipeline: [],
  processingPosePlanning: false,
  listPoseur: null,
  objectDayRowPlanning: []
}
const getters = {
  getAvailableTechnicians: state => state.technicians,
  getPlanning: state => state.planning,
  getAvailableFiliales: state => state.filiales,
  getAvailableDeposits: state => state.deposits,
  getAvailableYears: state => state.years,
  getAvailableWeeks: state => state.weeks,
  getPlanningError: state => state.error,
  getPlanningProcessing: state => state.processing,
  getPlanningPipeline: state => state.planningPipeline,
  getPosePlanningProcessing: state => state.processingPosePlanning,
  getListePoseurPlanning: state => state.listPoseur,
  getObjectDayRowPlanning: state => state.objectDayRowPlanning
}
const mutations = {
  SET_PLANNING_FILIALLES(state, payload) {
    state.filiales = payload
  },
  SET_PLANNING_DEPOSITS(state, payload) {
    state.deposits = payload
  },
  SET_PLANNING_YEARS(state, payload) {
    state.years = payload
  },
  SET_PLANNING_WEEKS(state, payload) {
    state.weeks = payload
  },
  INIT_PLANNING(state, payload) {
    state.planning = Planning.create(payload)
  },
  SET_PLANNING_TECHNICIAN(state, payload) {
    const technician = state.planning.technicians.find(
      t => t.id === payload.technician_id
    )
    if (technician) {
      technician.setWeekSummary(payload.totals_week)
      technician.setDays(payload.days)
      if (payload.zipcode) {
        technician.zipcode = payload.zipcode
      }
      if (payload.name) {
        technician.name = payload.name
      }
      technician.loading = false
    }
  },
  SET_PLANNING_HEAD_VALUES(state) {
    state.planning.computeGlobalValues()
  },
  REMOVE_PLANNING_PLANNING(state) {
    state.planning = null
  },
  SET_PLANNING_ERROR(state, payload) {
    state.error = payload
    state.processing = false
  },
  REMOVE_PLANNING_ERROR(state) {
    state.error = null
  },
  SET_PLANNING_PROCESSING(state, payload) {
    state.processing = payload
    state.error = null
  },
  SET_POSEUR_PLANNING_PROCESSING(state, payload) {
    state.processingPosePlanning = payload
    state.error = null
  },
  SET_POSEUR_PLANNING(state, payload) {
    state.listPoseur = Planning.create(payload)
  },
  SET_PLANNING_STAGE(state, payload) {
    payload.lead.cstage_id = payload.response.cstage_id
    payload.lead.cpipeline_id = payload.response.cpipeline_id
    payload.lead.csubstage_id = payload.response.csubstage_id
  },
  SET_PLANNING_ROW_STATUS(state, payload) {
    payload.row.active = payload.response[payload.row.id] ? true : false
    payload.day.computeRows()
  },
  SET_PLANNING_OPTIMISE(state, payload) {
    payload.day.optimised = payload.response.optimised
  },
  SET_PLANNING_POSE(state, payload) {
    // update lead
    payload.lead[payload.field + '_pose'] = payload.change.value
    // update day
    payload.day['total_' + payload.change.field] =
      payload.response[payload.change.field + '_d']
    payload.day.total_total_m_pose = payload.response.total_pose_d
    payload.day.pose_planifie_d = payload.response.pose_planifie_d
    // update technician
    payload.technician[payload.change.field] =
      payload.response[payload.change.field + '_w']
    payload.technician.total_total_pose_w = payload.response.total_pose_w
    payload.technician.pose_planifie_w = payload.response.pose_planifie_w
    // payload.technician.total_m_w = payload.response.total_m_w
    state.planning.computeGlobalValues()
  },
  SET_PLANNING_CEE_PHOTO_MEETING(state, payload) {
    payload.lead[payload.field] = payload.value
  },
  SET_PLANNING_SAV(state, payload) {
    payload.lead.sav = payload.value
  },
  SET_PLANNING_COMMENTS(state, payload) {
    payload.lead.setComments(payload.comments)
  },
  ADD_PLANNING_COMMENT(state, payload) {
    payload.lead.comments.list.push(payload.response)
    payload.lead.comments.count = payload.lead.comments.count + 1
  },
  SET_PLANNING_PIPELINES(state, payload) {
    if (payload && Array.isArray(payload)) {
      state.planningPipeline = payload.map(pipeline => new Pipeline(pipeline))
    }
  },
  SET_PLANNING_DO_NOTHING() {},
  SET_OBJECT_DAY_ROW_PLANNING(state, payload) {
    state.objectDayRowPlanning = []
    for (let index = 0; index < state.planning.technicians.length; index++) {
      const element = state.planning.technicians[index]
      if (
        element.id == payload.tech_ids &&
        element.days &&
        element.days.length
      ) {
        for (let j = 0; j < element.days.length; j++) {
          const day = element.days[j]
          if (day.date == payload.planningDate) {
            for (let x = 0; x < day.dayRows.length; x++) {
              let rdv = day.dayRows[x]
              if (rdv.index == payload.index) {
                rdv = DayRow.create(payload)
                state.objectDayRowPlanning = DayRow.create(payload)
              } else {
                state.objectDayRowPlanning.push(DayRow.create(payload))
                break
              }
            }
          }
        }
      }
    }
  }
}
const actions = {
  async initPlanning({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    commit('SET_PLANNING_PROCESSING', true)
    commit('REMOVE_PLANNING_PLANNING')
    try {
      const params = {
        planning: payload.planning,
        day_nbr: payload.day_nbr
      }
      if (payload && payload.tech_ids && payload.tech_ids.length) {
        for (let index = 0; index < payload.tech_ids.length; index++) {
          params.tech_ids = payload.tech_ids
        }
      }
      if (payload && payload.order) {
        params.order = payload.order
      }
      if (inti_Planning_Request) {
        inti_Planning_Request.cancel()
      }
      inti_Planning_Request = Axios.CancelToken.source()
      const response = await Axios.get(
        `${domain}/api/depots/${payload.depot_id}/months/${payload.month_id}/days/${payload.day_nbr}/technician`,
        // {{domain}}/api/depots/15/weeks/64/technicians
        {
          params,
          cancelToken: inti_Planning_Request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      if (response && response.data) {
        commit('INIT_PLANNING', response.data)
        commit('SET_PLANNING_PROCESSING', false)
      }
    } catch (error) {
      commit('INIT_PLANNING', {})
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_PLANNING_ERROR', 'unknown error')
        }
      }
    }
  },
  async fetchListePoseurPlanning({ commit }, payload) {
    commit(
      'SET_POSEUR_PLANNING_PROCESSING',
      payload && payload.loading == false ? false : true
    )
    try {
      const params = {
        planning: payload.planning,
        day_nbr: payload.day_nbr
      }
      if (payload && payload.order) {
        params.order = payload.order
      }
      const response = await Axios.get(
        `${domain}/api/depots/${payload.depot_id}/months/${payload.month_id}/days/${payload.day_nbr}/technician`,
        {
          params,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response && response.data) {
        commit('SET_POSEUR_PLANNING', response.data)
        commit('SET_POSEUR_PLANNING_PROCESSING', false)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_PLANNING_ERROR', 'unknown error')
        }
      }
    }
  },
  async fetchPipeline({ commit }) {
    try {
      const response = await Axios.get(domain + '/api/crm/cpipeline', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PLANNING_PIPELINES', response.data.data)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_PLANNING_ERROR', 'unknown error')
        }
      }
    }
  },
  async fetchPlanning({ commit }, payload) {
    // check for technicians
    commit('SET_PLANNING_PROCESSING', true)
    if (
      this.state.planning.deposits &&
      this.state.planning.deposits.length == 0
    ) {
      commit('SET_PLANNING_ERROR', ["Cette agence n'existe pas ."])
      return
    }
    if (
      !this.state.planning.planning.technicians ||
      !this.state.planning.planning.technicians.length
    ) {
      commit('SET_PLANNING_ERROR', [
        'Cette agence ne contient pas des techniciens'
      ])
      return
    }

    try {
      // extract first technician
      const technician = payload.tech_ids[0]
      let nextId = technician
      while (nextId !== null) {
        if (Router.history.current.name != 'planning-pose-id_day') {
          return
        }
        const data = {
          depot_id: this.state.planning.planning.depot_id,
          month_id: this.state.planning.planning.month_id,
          technician_id: nextId,
          planning: payload.planning,
          day_nbr: payload.day_nbr,
          tech_ids: payload.tech_ids
        }
        if (fetch_Planning_Request) {
          fetch_Planning_Request.cancel()
        }
        fetch_Planning_Request = Axios.CancelToken.source()
        const response = await Axios.post(domain + '/api/planningTech', data, {
          cancelToken: fetch_Planning_Request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        })
        if (response && response.data) {
          commit('SET_PLANNING_TECHNICIAN', response.data)

          if (response.data && response.data.next_technician_id) {
            nextId = response.data.next_technician_id
          } else {
            nextId = null
          }
        }
      }
      commit('SET_PLANNING_HEAD_VALUES')
      // dispatch('sendTotalsFilials')
      commit('SET_PLANNING_PROCESSING', false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        if (!Axios.isCancel(error)) {
          commit('SET_PLANNING_ERROR', 'unknown error')
        }
      }
    }
  },
  fetchObjectDayRowPlanning({ commit }, payload) {
    commit('SET_OBJECT_DAY_ROW_PLANNING', payload)
  },
  refrehPlanningPose({ commit }) {
    commit('REMOVE_PLANNING_PLANNING')
  },
  async fetchFiliales({ commit }) {
    let filiales = []
    try {
      const response = await Axios.get(domain + '/api/visible-filiales', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      if (response && response.data && response.data.data) {
        filiales = response.data.data
      }
      commit('SET_PLANNING_FILIALLES', filiales)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', 'unknown error')
      }
    }
  },
  async fetchDeposits({ commit }, filialeId) {
    let deposits = []
    this.state.planning.filiales.forEach(filiale => {
      if (filiale.id == filialeId) {
        if (filiale.depots && filiale.depots.data) {
          deposits = filiale.depots.data
        }
      }
    })
    commit('SET_PLANNING_DEPOSITS', deposits)
  },
  async fetchYears({ commit }) {
    const start = 2019
    const end = moment().year() + 1
    const years = []
    for (let index = start; index <= end; index++) {
      years.push({ value: index, text: index })
    }
    commit('SET_PLANNING_YEARS', years)
  },
  async fetchWeeks({ commit }, year) {
    let weeks = []
    try {
      const response = await Axios.post(
        domain + '/api/weeks',
        { year: year },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response && response.data && response.data.data) {
        weeks = response.data.data
      }
      const maxWeek = moment().weeksInYear()
      const weekRange = maxWeek - moment().week()
      const nextRange = weekRange <= 7 ? 7 - weekRange : 0
      commit(
        'SET_PLANNING_WEEKS',
        weeks.map(week => {
          if (year == moment().year() && week.week_nbr > moment().week() + 7) {
            week.disabled = true
          } else if (year == moment().year() + 1) {
            if (week.week_nbr > nextRange) {
              week.disabled = true
            }
          } else if (year > moment().year()) {
            week.disabled = true
          }
          return { ...week, name: `S${week.week_nbr} - ${week.year}` }
        })
      )
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', 'unknown error')
      }
    }
  },
  async fetchComment({ commit }, payload) {
    try {
      const response = await Axios.get(
        domain + `/api/projets/${payload.project_id}/planningComments`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PLANNING_COMMENTS', {
        lead: payload,
        comments: response.data.data
      })
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', 'unknown error')
      }
    }
  },
  async updateFieldStage({ commit }, payload) {
    try {
      commit('REMOVE_PLANNING_ERROR')
      if (payload && payload.change && payload.lead) {
        const response = await Axios.post(
          `${domain}/api/projets/${payload.lead.project_id}/stage`,
          { sub_stage_id: payload.change.csubstage_id },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_PLANNING_STAGE', { ...payload, response: response.data })
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', 'unknown error')
      }
    }
  },
  async updateFieldPose({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    try {
      if (
        payload &&
        payload.change &&
        payload.weekId &&
        payload.lead &&
        payload.day &&
        payload.technician
      ) {
        const value = parseInt(payload.change.value)
        const data = {
          week_id: payload.weekId,
          planning_day_id: payload.day.id,
          [payload.change.field]: isNaN(value) ? 0 : value
        }

        const response = await Axios.post(
          domain +
            '/api/projets/' +
            payload.lead.project_id +
            '/edit-pose-field',
          data,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_PLANNING_POSE', {
          ...payload,
          response: response.data
        })
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async changeCEEPhotoMeeting({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    try {
      if (payload && payload.field && payload.lead) {
        const data = {
          [payload.field]: payload.value
        }
        await Axios.put(
          `${domain}/api/crm/projets/${payload.lead.project_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_PLANNING_CEE_PHOTO_MEETING', payload)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async updateFieldOptimise({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    try {
      if (payload && payload.day) {
        const response = await Axios.get(
          `${domain}/api/planning-day-tech/${payload.day.id}/field-optimised`,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_PLANNING_OPTIMISE', {
          ...payload,
          response: response.data
        })
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async updateRowStatus({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    try {
      if (payload && payload.day && payload.row) {
        const response = await Axios.get(
          `${domain}/api/planning-day-tech/${payload.day.id}/row-index-${payload.row.index}-on-off`,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        commit('SET_PLANNING_ROW_STATUS', {
          ...payload,
          response: response.data
        })
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async addCommentToLead({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    try {
      const response = await Axios.post(
        `${domain}/api/projets/${payload.lead.project_id}/planningComments`,
        { body: payload.text },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('ADD_PLANNING_COMMENT', {
        ...payload,
        response: response.data.data
      })
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async getSimulatedTrajet({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    try {
      if (payload && payload.origin && payload.destination) {
        const headers = new Headers()

        headers.append('Content-Type', 'application/x-www-form-urlencoded')
        headers.append(
          'Authorization',
          `Bearer ${this.state.currentUser.token}`
        )

        const body = new URLSearchParams()
        body.append('start_address', payload.origin)
        body.append('end_address', payload.destination)

        var requestOptions = {
          method: 'POST',
          headers: headers,
          body: body,
          redirect: 'manual'
        }
        commit('SET_PLANNING_DO_NOTHING')
        const response = await fetch(
          `${domain}/api/calc-trajet-test`,
          requestOptions
        )

        const result = await response.json()

        if (result.trajet_duration) {
          return result.trajet_duration
        } else {
          return 0
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
      return 0
    }
  },
  sendTotalsFilials({ commit }) {
    try {
      Axios.post(
        `${domain}/api/store-totals-filiales/${this.state.planning.planning.filial_id}/depots/${this.state.planning.planning.depot_id}/weeks/${this.state.planning.planning.week_nbr}`,
        {
          total_planifie: this.state.planning.planning.g_planifie,
          nbr_planifie: this.state.planning.planning.g_planifie_count,
          total_pose: this.state.planning.planning.g_pose,
          nbr_pose: this.state.planning.planning.g_pose_count,
          percentage_pose_planifie: this.state.planning.planning.g_planifie_pose
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async changeSAV({ commit }, payload) {
    commit('REMOVE_PLANNING_ERROR')
    try {
      if (payload) {
        const response = await Axios.get(
          `${domain}/api/projets/${payload.project_id}/sav`,
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        let value = payload.sav
        if (response && response.data) {
          value = response.data.sav
        }
        commit('SET_PLANNING_SAV', { lead: payload, value: value })
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PLANNING_ERROR', error.response.data.error.messages)
      } else if (error.message) {
        commit('SET_PLANNING_ERROR', [error.message])
      } else {
        commit('SET_PLANNING_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  getSimulatedPose({ commit }, payload) {
    if (payload) {
      const s = payload.surface_souffle ? parseInt(payload.surface_souffle) : 0
      const d = payload.surface_deroule ? parseInt(payload.surface_deroule) : 0
      const r = payload.surface_rampant ? parseInt(payload.surface_rampant) : 0
      const m = payload.surface_mur ? parseInt(payload.surface_mur) : 0
      const p = payload.surface_plafond ? parseInt(payload.surface_plafond) : 0
      const vs = payload.surface_vide_sanitaire
        ? parseInt(payload.surface_vide_sanitaire)
        : 0
      commit('SET_PLANNING_DO_NOTHING')
      return s * 1.8 + d * 1.2 + r * 3 + m * 2.4 + p * 2.4 + vs * 2.4
    }
    return 0
  },
  getSimulatedCamion({ commit }, payload) {
    if (payload) {
      const s = payload.surface_souffle ? parseInt(payload.surface_souffle) : 0
      const d = payload.surface_deroule ? parseInt(payload.surface_deroule) : 0
      const r = payload.surface_rampant ? parseInt(payload.surface_rampant) : 0
      const m = payload.surface_mur ? parseInt(payload.surface_mur) : 0
      const p = payload.surface_plafond ? parseInt(payload.surface_plafond) : 0
      const vs = payload.surface_vide_sanitaire
        ? parseInt(payload.surface_vide_sanitaire)
        : 0
      commit('SET_PLANNING_DO_NOTHING')
      return (
        s * 0.0033 + d * 0.0043 + r * 0.0014 + m * 0.01 + p * 0.01 + vs * 0.01
      )
    }
    return 0
  },
  getSimulatedTotalSurface({ commit }, payload) {
    if (payload) {
      const s = payload.surface_souffle ? parseInt(payload.surface_souffle) : 0
      const d = payload.surface_deroule ? parseInt(payload.surface_deroule) : 0
      const r = payload.surface_rampant ? parseInt(payload.surface_rampant) : 0
      const m = payload.surface_mur ? parseInt(payload.surface_mur) : 0
      const p = payload.surface_plafond ? parseInt(payload.surface_plafond) : 0
      const vs = payload.surface_vide_sanitaire
        ? parseInt(payload.surface_vide_sanitaire)
        : 0

      commit('SET_PLANNING_DO_NOTHING')
      return s + d + r + m + p + vs
    }
    return 0
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
