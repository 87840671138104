import Entity from '../Entity'
import Day from './Day'

export default class Technician extends Entity {
  constructor(data) {
    super(data.id)
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.name = `${data.first_name} ${data.last_name}`
    this.color_pose = data.color_pose
    this.loading = true
    // week summary
    this.total_trajet_w = 0
    this.total_m_w = 0
    this.total_total_pose_w = 0
    this.pose_planifie_w = 0
    this.total_pose_trajet_w = 0
    this.ca_w = 0

    this.s_planifie = 0
    this.d_planifie = 0
    this.r_planifie = 0
    this.m_planifie = 0
    this.p_planifie = 0
    this.pignon_planifie = 0
    this.pignon_planif = 0
    this.vs_planifie = 0
    this.total_ite_planifie = 0
    this.total_iti_planifie = 0
    this.total_planifie_w = 0
    this.s_pose = 0
    this.surface_souffle_pose = 0
    this.d_pose = 0
    this.surface_deroule_pose = 0
    this.r_pose = 0
    this.surface_rampant_pose = 0
    this.m_pose = 0
    this.surface_mur_pose = 0
    this.p_pose = 0
    this.surface_plafond_pose = 0
    this.pignon_pose = 0
    this.surface_pignon_pose = 0
    this.vs_pose = 0
    this.surface_vide_sanitaire_pose = 0
    this.iti_pose = 0
    this.ite_pose = 0
    this.total_pose_w = 0
    this.zipcode = data.zipcode

    // days
    this.days = []
  }

  setWeekSummary(data) {
    if (data) {
      this.total_trajet_w = data.total_trajet_w
      this.total_m_w = data.total_m_w
      this.total_total_pose_w = data.total_total_pose_w
      this.total_pose_trajet_w = data.total_pose_trajet_w
      this.pose_planifie_w = data.pose_planifie_w
      this.ca_w = data.ca_w

      this.s_planifie = data.s_planifie
      this.d_planifie = data.d_planifie
      this.r_planifie = data.r_planifie
      this.m_planifie = data.m_planifie
      this.p_planifie = data.p_planifie
      this.pignon_planifie = data.pignon_planifie
      this.pignon_planif = data.pignon_planif
      this.vs_planifie = data.vs_planifie
      this.total_ite_planifie = data.total_ite_planifie
      this.total_iti_planifie = data.total_iti_planifie
      this.total_planifie_w = data.total_planifie_w
      this.s_pose = data.s_pose
      this.surface_souffle_pose = data.surface_souffle_pose
      this.d_pose = data.d_pose
      this.surface_deroule_pose = data.surface_deroule_pose
      this.r_pose = data.r_pose
      this.surface_rampant_pose = data.surface_rampant_pose
      this.m_pose = data.m_pose
      this.surface_mur_pose = data.surface_mur_pose
      this.p_pose = data.p_pose
      this.surface_plafond_pose = data.surface_plafond_pose
      this.pignon_pose = data.pignon_pose
      this.surface_pignon_pose = data.surface_pignon_pose
      this.vs_pose = data.vs_pose
      this.surface_vide_sanitaire_pose = data.surface_vide_sanitaire_pose
      this.ite_pose = data.ite_pose
      this.iti_pose = data.iti_pose
      this.total_pose_w = data.total_pose_w
    }
  }

  setDays(data) {
    if (data && Array.isArray(data)) {
      this.days = data.map(day => Day.create(day))
    }
  }

  static create(data) {
    if (!data) {
      throw new Error('Technician::create: "data" is undefined')
    }
    return new Technician(data)
  }
}
