import Axios from 'axios'
import store from '@/store'
Axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message == 'Unauthenticated.'
    ) {
      store.dispatch('logout')
    } else {
      return Promise.reject(error)
    }
  }
)

export default Axios
