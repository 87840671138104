import Entity from '../Entity'
import Lead from './Lead'
import DayRow from './DayRow'

export default class Day extends Entity {
  constructor(data) {
    super(data.totals_day.planning_day_id)
    this.date = data.date

    // Leads
    this.leads = data.leads.map(lead => Lead.create(lead))

    // Day summary
    this.total_trajet = data.totals_day.total_trajet
    this.total_pose = data.totals_day.total_pose
    this.total_total_h = data.totals_day.total_total_h
    this.total_s_planifie = data.totals_day.total_s_planifie
    this.total_d_planifie = data.totals_day.total_d_planifie
    this.total_r_planifie = data.totals_day.total_r_planifie
    this.total_m_planifie = data.totals_day.total_m_planifie
    this.total_p_planifie = data.totals_day.total_p_planifie
    this.total_pignon_planifie = data.totals_day.total_pignon_planifie
    // planning commercial
    this.total_pignon_planif = data.totals_day.total_pignon_planif
    this.total_vs_planifie = data.totals_day.total_vs_planifie
    this.total_ite_planifie = data.totals_day.total_ite_planifie
    this.total_iti_planifie = data.totals_day.total_iti_planifie
    this.ite_day = data.totals_day.ite_day
    this.iti_day = data.totals_day.iti_day
    this.total_total_m_planifie = data.totals_day.total_total_m_planifie
    this.total_s_pose = data.totals_day.total_s_pose
    this.total_d_pose = data.totals_day.total_d_pose
    this.total_r_pose = data.totals_day.total_r_pose
    this.total_m_pose = data.totals_day.total_m_pose
    this.total_p_pose = data.totals_day.total_p_pose
    this.total_pignon_pose = data.totals_day.total_pignon_pose
    this.total_vs_pose = data.totals_day.total_vs_pose
    this.total_ite_pose = data.totals_day.total_ite_pose
    this.total_iti_pose = data.totals_day.total_iti_pose
    this.total_total_m_pose = data.totals_day.total_total_m_pose
    this.ca_d = data.totals_day.ca_d
    this.pose_planifie_d = data.totals_day.pose_planifie_d
    this.optimised = data.totals_day.optimised
    this.planning = data.totals_day.planning
    // set day rows
    this.dayRows = []
    this.dayRows.push(
      DayRow.create({
        id: 'row_index_1_on',
        index: 1,
        active: data.totals_day.row_index_1_on === 1 ? true : false,
        heure: data.totals_day.row_index_1_hour
          ? data.totals_day.row_index_1_hour.split(':')[0] + 'h'
          : '07h',
        lead:
          this.leads && this.leads.length
            ? this.leads.filter(item => item.hour_previsite == '07h')
            : [],
        projet_tech: this.leads[0],
        planningDate: data.date
      })
    )
    this.dayRows.push(
      DayRow.create({
        id: 'row_index_2_on',
        index: 2,
        active: data.totals_day.row_index_2_on === 1 ? true : false,
        heure: data.totals_day.row_index_2_hour
          ? data.totals_day.row_index_2_hour.split(':')[0] + 'h'
          : '08h',
        lead:
          this.leads && this.leads.length
            ? this.leads.filter(item => item.hour_previsite == '08h')
            : [],
        projet_tech: this.leads[1],
        planningDate: data.date
      })
    )

    // PLANNING AMO
    if (
      this.planning == 'planning-amo' ||
      this.planning == 'planning-visiteur-tech'
    ) {
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_3_on',
          index: 3,
          active: data.totals_day.row_index_3_on === 1 ? true : false,
          heure: data.totals_day.row_index_3_hour
            ? data.totals_day.row_index_3_hour.split(':')[0] + 'h'
            : '09h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '09h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_4_on',
          index: 4,
          active: data.totals_day.row_index_4_on === 1 ? true : false,
          heure: data.totals_day.row_index_4_hour
            ? data.totals_day.row_index_4_hour.split(':')[0] + 'h'
            : '10h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '10h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_5_on',
          index: 5,
          active: data.totals_day.row_index_5_on === 1 ? true : false,
          heure: data.totals_day.row_index_5_hour
            ? data.totals_day.row_index_5_hour.split(':')[0] + 'h'
            : '11h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '11h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_6_on',
          index: 6,
          active: data.totals_day.row_index_6_on === 1 ? true : false,
          heure: data.totals_day.row_index_6_hour
            ? data.totals_day.row_index_6_hour.split(':')[0] + 'h'
            : '12h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '12h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_7_on',
          index: 7,
          active: data.totals_day.row_index_7_on === 1 ? true : false,
          heure: data.totals_day.row_index_7_hour
            ? data.totals_day.row_index_7_hour.split(':')[0] + 'h'
            : '13h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '13h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_8_on',
          index: 8,
          active: data.totals_day.row_index_8_on === 1 ? true : false,
          heure: data.totals_day.row_index_8_hour
            ? data.totals_day.row_index_8_hour.split(':')[0] + 'h'
            : '14h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '14h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_9_on',
          index: 9,
          active: data.totals_day.row_index_9_on === 1 ? true : false,
          heure: data.totals_day.row_index_9_hour
            ? data.totals_day.row_index_9_hour.split(':')[0] + 'h'
            : '15h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '15h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_10_on',
          index: 10,
          active: data.totals_day.row_index_10_on === 1 ? true : false,
          heure: data.totals_day.row_index_10_hour
            ? data.totals_day.row_index_10_hour.split(':')[0] + 'h'
            : '16h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '16h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_11_on',
          index: 11,
          active: data.totals_day.row_index_11_on === 1 ? true : false,
          heure: data.totals_day.row_index_11_hour
            ? data.totals_day.row_index_11_hour.split(':')[0] + 'h'
            : '17h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '17h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_12_on',
          index: 12,
          active: data.totals_day.row_index_12_on === 1 ? true : false,
          heure: data.totals_day.row_index_12_hour
            ? data.totals_day.row_index_12_hour.split(':')[0] + 'h'
            : '18h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '18h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_13_on',
          index: 13,
          active: data.totals_day.row_index_13_on === 1 ? true : false,
          heure: data.totals_day.row_index_13_hour
            ? data.totals_day.row_index_13_hour.split(':')[0] + 'h'
            : '19h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '19h')
              : []
        })
      )
      this.dayRows.push(
        DayRow.create({
          id: 'row_index_14_on',
          index: 14,
          active: data.totals_day.row_index_14_on === 1 ? true : false,
          heure: data.totals_day.row_index_14_hour
            ? data.totals_day.row_index_14_hour.split(':')[0] + 'h'
            : '20h',
          lead:
            this.leads && this.leads.length
              ? this.leads.filter(item => item.hour_previsite == '20h')
              : []
        })
      )
    }
    this.computeRows()
  }

  computeRows() {
    if (
      this.planning == 'planning-amo' ||
      this.planning == 'planning-visiteur-tech'
    ) {
      for (let index = 0; index < this.dayRows.length; index++) {
        const row = this.dayRows[index]
        row.lead = null
        row.simulatable = false
        if (row.active) {
          if (
            !row.lead &&
            Array.isArray(row.leads) &&
            (!row.leads || !row.leads.length)
          ) {
            row.simulatable = true
            // simulationConfigured = true
            break
          }
        }
      }
    } else {
      const leads = [...this.leads]
      let simulationConfigured = false
      this.dayRows.forEach(row => {
        row.lead = null
        row.simulatable = false
        if (row.active) {
          const shifted = leads.shift()
          if (shifted) {
            row.lead = shifted
          } else if (!simulationConfigured) {
            row.simulatable = true
            simulationConfigured = true
          }
        }
      })
    }
  }

  static create(data) {
    if (!data) {
      throw new Error('Day::create: "data" is undefined')
    }
    return new Day(data)
  }
}
